// Slider current position
 var sliderCurrentPos = 1;
// Slider total amount of slides
 var sliderTotal = 3;
// Slider time for each slide (in ms)
// var sliderTime = 5000;

document.getElementById('arrow-left').addEventListener('click', () => {
  // clearInterval(sliderInterval);
  sliderCurrentPos -= 1;
  sliderCurrentPos < 1 ? sliderCurrentPos = sliderTotal : '';
  changeSlide();
});

document.getElementById('arrow-right').addEventListener('click', () => {
  // clearInterval(sliderInterval);
  sliderCurrentPos += 1;
  sliderCurrentPos > sliderTotal ? sliderCurrentPos = 1 : '';
  changeSlide();
});

/* var sliderInterval = setInterval(() => {
  sliderCurrentPos += 1;
  sliderCurrentPos > sliderTotal ? sliderCurrentPos = 1 : '';
  changeSlide();
}, sliderTime); */

function changeSlide() {
  document.getElementById('slider').removeAttribute('class');
  document.getElementById('slider').className = 'step-' + sliderCurrentPos;
};

document.getElementById('open-menu').addEventListener('click', () => {
  toggleMenu();
});

document.getElementById('menu-burger').addEventListener('click', () => {
  toggleMenu();
});

function toggleMenu() {
  document.getElementById('menu-burger').classList.toggle('open');
  document.getElementById('open-menu').classList.toggle('active');
};